var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { col: "12", sm: "4", md: "3", lg: "2" } },
        [
          _c("CSelect", {
            attrs: { value: _vm.tf, placeholder: "TF", options: _vm.tfOptions },
            on: {
              "update:value": function ($event) {
                _vm.tf = $event
              },
            },
          }),
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { col: "12", sm: "4", md: "3", lg: "2" } },
        [
          _c("CSelect", {
            attrs: {
              value: _vm.param,
              placeholder: _vm.$t("screener.treemap.parameter"),
              options: _vm.paramOptions,
            },
            on: {
              "update:value": function ($event) {
                _vm.param = $event
              },
            },
          }),
        ],
        1
      ),
      _c(
        "CCol",
        {
          staticClass: "d-flex",
          attrs: { col: "12", sm: "4", md: "3", lg: "2" },
        },
        [
          _c("CSelect", {
            staticClass: "flex-grow-1",
            attrs: {
              value: _vm.viewCount,
              placeholder: _vm.$t("screener.treemap.numberOfCoins"),
              options: _vm.viewCountOptions,
            },
            on: {
              "update:value": function ($event) {
                _vm.viewCount = $event
              },
            },
          }),
          _vm.viewCount
            ? _c(
                "CButton",
                {
                  staticClass: "mb-3 ml-2",
                  attrs: { color: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.viewCount = null
                    },
                  },
                },
                [_c("CIcon", { attrs: { name: "cil-x" } })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.param === "slidingVolume" || _vm.param === "slidingVolumePct"
        ? _c(
            "CCol",
            {
              staticClass: "mb-2",
              attrs: { col: "12", sm: "12", md: "6", lg: "6" },
            },
            [
              _vm._l(_vm.colorListArr, function (item, i) {
                return _c(
                  "CButton",
                  {
                    key: i,
                    staticClass: "mr-1 filter-color mb-1",
                    class: _vm.filterColorClass(item),
                    style: { background: item.color },
                    on: {
                      click: function ($event) {
                        return _vm.setFilterColor(item)
                      },
                    },
                  },
                  [_c("span", { staticClass: "invisible" }, [_vm._v("ttt")])]
                )
              }),
              _vm.filterColorInternal.length
                ? _c(
                    "CButton",
                    {
                      staticClass: "mb-1 ml-2",
                      attrs: { color: "danger" },
                      on: { click: _vm.resetFilterColor },
                    },
                    [_c("CIcon", { attrs: { name: "cil-x" } })],
                    1
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }