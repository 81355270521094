var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "treemap-wrap w-100 h-100 d-flex flex-column" },
    [
      _c("Controls", {
        attrs: { "tf-options": _vm.tfOptions },
        on: {
          tfChange: function ($event) {
            _vm.tf = $event
            _vm.changeParam()
          },
          paramChange: function ($event) {
            _vm.param = $event
            _vm.changeParam()
          },
          viewCountChange: function ($event) {
            _vm.viewCount = $event
            _vm.changeParam()
          },
          filterColorChange: function ($event) {
            _vm.filterColor = $event
            _vm.changeParam()
          },
        },
      }),
      !_vm.myChartData || _vm.myChartData.length === 0
        ? _c("p", { staticClass: "m-0" }, [
            _vm._v(_vm._s(_vm.$t("general.noData"))),
          ])
        : _vm._e(),
      _c("div", { attrs: { id: "visualization" } }),
      _c("CandlesGraphModal", {
        ref: "candles",
        attrs: {
          settings: _vm.settings,
          rows: _vm.myChartData,
          "client-id": _vm.clientId,
          "hide-notification-modal": _vm.isActiveFutures,
          "is-futures": _vm.isActiveFutures,
        },
        on: {
          close: function ($event) {
            return _vm.$emit("closeGraph")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }