<template>
  <CRow>
    <CCol col="12" sm="4" md="3" lg="2">
      <CSelect
        :value.sync="tf"
        placeholder="TF"
        :options="tfOptions"
      />
    </CCol>

    <CCol col="12" sm="4" md="3" lg="2">
      <CSelect
        :value.sync="param"
        :placeholder="$t('screener.treemap.parameter')"
        :options="paramOptions"
      />
    </CCol>

    <CCol col="12" sm="4" md="3" lg="2" class="d-flex">
      <CSelect
        class="flex-grow-1"
        :value.sync="viewCount"
        :placeholder="$t('screener.treemap.numberOfCoins')"
        :options="viewCountOptions"
      />
      <CButton
        v-if="viewCount"
        class="mb-3 ml-2"
        color="danger"
        @click="viewCount = null"
      >
        <CIcon name="cil-x" />
      </CButton>
    </CCol>
    <!--      <button @click="myChart.zoomReset()">zoom reset</button>-->
    <CCol
      v-if="param === 'slidingVolume' || param === 'slidingVolumePct'"
      col="12" sm="12" md="6" lg="6" class="mb-2"
    >
      <CButton
        v-for="(item, i) in colorListArr"
        :key="i"
        :style="{background: item.color}"
        class="mr-1 filter-color mb-1"
        :class="filterColorClass(item)"
        @click="setFilterColor(item)"
      >
        <span class="invisible">ttt</span>
      </CButton>
      <CButton
        v-if="filterColorInternal.length"
        color="danger"
        class="mb-1 ml-2"
        @click="resetFilterColor"
      >
        <CIcon name="cil-x" />
      </CButton>
    </CCol>
  </CRow>
</template>

<script>
import colorList from "@/assets/js/treemap/colorList.json";
import {isObject} from "lodash";

export default {
  name: "Controls",
  props: {
    tfOptions: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      colorList,
      paramInternal: this.$store.state.settings?.treemap?.param || 'slidingVolume',
      tfInternal: this.$store.state.settings?.treemap?.tf || '5m',
      viewCountInternal: this.$store.state.settings?.treemap?.viewCount || null,
      filterColorInternal: this.initFilterColorInternal(),

      viewCountOptions: new Array(30).fill(10).map((v,i)=>v*(i+1)),
      colorListArr: [],
      paramOptions: [
        {
          label: 'Volume',
          value: 'slidingVolume'
        },
        {
          label: 'Volume change',
          value: 'slidingVolumePct'
        },
        {
          label: 'Price change',
          value: 'pricePct'
        },
        {
          label: 'Volatility',
          value: 'volatility'
        },
        {
          label: 'RSI change',
          value: 'rsiPct'
        }
      ]
    }
  },
  mounted() {
    this.colorListArr = this.createArr()
    this.$emit('tfChange', this.tf)
    this.$emit('paramChange', this.param)
    this.$emit('viewCountChange', this.viewCount)
    this.$emit('filterColorChange', this.filterColorInternal)
  },
  computed: {
    tf: {
      get () {
        return this.tfInternal
      },
      set (val) {
        this.$emit('tfChange', val)
        this.resetFilterColor()
        this.tfInternal = val
        this.$store.dispatch("setKey", {
          namespace: 'treemap',
          key: 'tf',
          value: val
        })
      }
    },
    param: {
      get () {
        return this.paramInternal
      },
      set (val) {
        this.$emit('paramChange', val)
        this.resetFilterColor()
        this.paramInternal = val
        this.$store.dispatch("setKey", {
          namespace: 'treemap',
          key: 'param',
          value: val
        })
      }
    },
    viewCount: {
      get () {
        return this.viewCountInternal
      },
      set (val) {
        this.$emit('viewCountChange', val)
        this.resetFilterColor()
        this.viewCountInternal = val
        this.$store.dispatch("setKey", {
          namespace: 'treemap',
          key: 'viewCount',
          value: val
        })
      }
    }
  },
  methods: {
    setFilterColor (val) {
      const findColorIndex = this.filterColorInternal.findIndex(el => el?.color === val.color)

      if (findColorIndex > -1) {
        this.filterColorInternal.splice(findColorIndex, 1)
      } else {
        this.filterColorInternal.push(val)
      }

      this.$emit('filterColorChange', this.filterColorInternal)

      this.$store.dispatch("setKey", {
        namespace: 'treemap',
        key: 'filterColor',
        value: this.filterColorInternal
      })
    },
    resetFilterColor () {
      this.filterColorInternal.splice(0)

      this.$emit('filterColorChange', this.filterColorInternal)

      this.$store.dispatch("setKey", {
        namespace: 'treemap',
        key: 'filterColor',
        value: this.filterColorInternal
      })
    },
    createArr () {
      const json = colorList
      const jsonLength = Object.keys(json).length
      const arr = []

      let i = jsonLength - 1
      while (i >= 0) {
        const key = String(i)
        arr.push({
          level: i,
          type: 'positive',
          color: json[key]['positive']
        })
        i--
      }

      i = 0
      while (i < jsonLength) {
        const key = String(i)
        arr.push({
          level: i,
          type: 'negative',
          color: json[key]['negative']
        })
        i++
      }

      return arr
    },
    filterColorClass (item) {
      if (!this.filterColorInternal) return ''
      if (this.filterColorInternal.some(el => el.color === item.color)) return '_active'
      return ''
    },
    initFilterColorInternal() {
      const filterColor = this.$store.state.settings?.treemap?.filterColor

      if (Array.isArray(filterColor)) {
        return filterColor
      } else if (filterColor && isObject(filterColor)) {
        return [filterColor]
      } else return []
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-color {
  border: 2px solid transparent !important;
  &._active {
    border-color: var(--primary) !important;
  }
}
</style>
