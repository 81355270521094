<template>
  <div class="treemap-wrap w-100 h-100 d-flex flex-column">
    <Controls
      :tf-options="tfOptions"
      @tfChange="tf = $event; changeParam()"
      @paramChange="param = $event; changeParam()"
      @viewCountChange="viewCount = $event; changeParam()"
      @filterColorChange="filterColor = $event; changeParam()"
    />

    <p v-if="!myChartData || myChartData.length === 0" class="m-0">{{ $t('general.noData') }}</p>

    <div id="visualization"></div>

    <CandlesGraphModal
      ref="candles"
      :settings="settings"
      :rows="myChartData"
      :client-id="clientId"
      :hide-notification-modal="isActiveFutures"
      :is-futures="isActiveFutures"
      @close="$emit('closeGraph')"
    />
  </div>
</template>

<script>
import Treemap from 'treemap-chart'
import screenerDataAdapter from "@/assets/js/screenerDataAdapter";
import axios from "axios";
import toastr from "toastr";
import throttle from "lodash/throttle";
import CandlesGraphModal from "@/components/screener/tables/CandlesGraphModal";
import { mapGetters, mapActions } from "vuex";
import {
  getColor,
  getColorSimple,
  diff,
  getTooltipContent, getLevelsArr, filterByColor
} from "@/assets/js/treemap/helpers";
import Controls from "@/components/treemap/Controls";
import {CLIENT_ID_CANDLES_BINANCE_FUTURES, CLIENT_ID_DEFAULT, SCREENER_PAGE_FUTURES} from "@/shared/constants";
import {createWatcherExhangeAndScreen} from "@/mixins/watch-exhange-and-screen";

export default {
  name: "Treemap",
  components: {
    Controls,
    CandlesGraphModal,
  },
  props: {
    settings: Object,
    isPaused: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [
    createWatcherExhangeAndScreen(({ getData }) => getData()),
  ],
  data () {
    return {
      adapter: screenerDataAdapter,
      tf: null,
      param: null,
      viewCount: null,
      myChart: null,
      loadingData: false,
      timerIntervalDefault: 10000,
      myChartNode: null,
      myTooltipNode: null,
      myChartData: null,
      lashTickerClicked: null,
      tfOptions: [
        {
          value: '5m',
          label: '5M'
        },
        {
          value: '15m',
          label: '15M'
        },
        {
          value: '30m',
          label: '30M'
        },
        {
          value: '1h',
          label: '1H'
        },
        {
          value: '2h',
          label: '2H'
        },
        {
          value: '4h',
          label: '4H'
        },
        {
          value: '12h',
          label: '12H'
        },
        {
          value: '1d',
          label: '1D'
        }
      ],
      filterColor: null,
      levels: [],
      isInit: false,
    }
  },
  computed: {
    user(){
      return this.$store.state.user.userData
    },
    ...mapGetters({
      cancelToken: 'cancelToken',
      tablesData: 'tablesData',
      isBrowserTabActive: 'isBrowserTabActive',
      isDesktop: 'mediaQuery/isDesktop',
      isGuest: 'user/isGuest'
    }),
    rows () {
      return this.adapter.data
    },
    clientId() {
      let clientId = CLIENT_ID_DEFAULT
      const queryParams = this.$route.params
      const screenQueryParam = queryParams.screen

      if (screenQueryParam === SCREENER_PAGE_FUTURES) {
        clientId = CLIENT_ID_CANDLES_BINANCE_FUTURES
      }

      return clientId
    },
    isActiveFutures() {
      return this.currentScreenAndExchange.screen === SCREENER_PAGE_FUTURES
    }
  },
  mounted() {
    this.myChartNode = document.getElementById('visualization')
    if (this.myChartNode) {
      this.initMyChart()
      this.getData()
      this.myChartNode.addEventListener('mousemove', this.transformTooltip)
      window.addEventListener('resize', this.resizeThrottle.bind(this))
    }
  },
  watch: {
    loadingData (val) {
      this.$emit('changeLoading', val)
    },
    'settings.selectedPairs': function () {
      if (this.adapter.filteredData.length > this.viewCount) {
        this.viewCount = null
      }
      this.setDataMyChart()
    },
    isPaused(val) {
      if (val) {
        if (this.timer) {
          clearTimeout(this.timer)
        }
      } else {
        if (!this.loadingData) {
          this.getData()
        }
      }
    },
    isBrowserTabActive(val) {
      if (!val) {
        if (this.timer) {
          clearTimeout(this.timer)
        }
      } else {
        if (!this.loadingData && !this.isPaused) {
          this.getData()
        }
      }
    },
    tablesData: {
      deep: true,
      handler: function (val) {
        //if (val && Array.isArray(val) && !this.isBrowserTabActive) {
          this.adapter.data = val
        //}
      },
    },
  },
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    if (this.cancelToken) {
      this.cancelToken.cancel()
    }
    window.removeEventListener('resize', this.resizeThrottle)
    this.destroyMyChart()
  },
  methods: {
    getData () {
      // if (this.cancelEvent) {
      //   this.cancelEvent()
      // }

      if (this.cancelToken) {
        this.cancelToken.cancel()
      }

      this.loadingData = true
      this.$store.commit('setCancelToken', axios.CancelToken.source())

      const getURL = this.currentScreenAndExchange.screen === SCREENER_PAGE_FUTURES
        ? `/api/v3/${this.currentScreenAndExchange.exchange}_perp/screener/tables`
        : '/api/v2/screener/tables';

      axios.get(getURL, {
        // cancelToken: new axios.CancelToken((c) => {
        //   this.cancelEvent = c
        // })
        cancelToken: this.cancelToken.token
      })
        .then(res => {
          this.$store.commit('setTablesData', res?.data?.data)
          const arr = this.tablesData

          if (arr && Array.isArray(arr)) {
            this.adapter.data = arr
          }

          this.loadingData = false
          const delay = res?.data?.delay
          this.startTimer(delay)
          this.setDataMyChart()
        })
        .catch((e) => {
          if (e instanceof axios.Cancel) {
            toastr.remove()
          } else if (e?.response?.status !== 401) {
            this.loadingData = false
            this.startTimer()
          }
        })
      // this.adapter.data = 'random'
    },
    startTimer (delay) {
      if (this.isPaused || !this.isBrowserTabActive) return

      let localDelay = this.timerIntervalDefault
      if(this.isGuest){
        // каждую шестую минуту
        localDelay = 360000 - Date.now() % 300000
        if(localDelay < this.timerIntervalDefault){
          localDelay = this.timerIntervalDefault
        }
      } else if (delay && typeof delay === 'number') {
        localDelay = delay
      }

      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        if (this.loadingData) return
        this.getData()
      }, localDelay)
    },
    ...mapActions('promo', ['toggleShowPromoModal']),
    initMyChart () {
      const width = this.myChartNode.getBoundingClientRect().width
      const height = this.myChartNode.getBoundingClientRect().height

      this.myChart = Treemap()
        .width(width)
        .height(height)
        .padding(0)
        .minBlockArea(0)
        .transitionDuration(0)
        .excludeRoot(true)
        .onClick((d) => {
          const openGraph = () => {
            // const access = this.$store.getters['user/getPermissions']('view_screener_graphs')
            // if (!access) return
            if(this.user.getAccess(1)){
              this.$emit('openGraph')
              const tfIndex = this.tfOptions.findIndex(v => v.value === this.tf)
              this.$refs.candles.openCandlesGraph(d.nameFull, tfIndex)
            }else{
              this.toggleShowPromoModal({show: true, content: { id: 'pro', sliderData: [{ videoName: "heatmap", isVideo: true}]}})
            }
          }

          if (this.isDesktop) {
            openGraph()
          } else {
            if (this.lashTickerClicked === d.nameFull) {
              openGraph()
              this.lashTickerClicked = null
            } else {
              this.lashTickerClicked = d.nameFull
            }
          }
        })
        (this.myChartNode)

      this.myTooltipNode = this.myChartNode.querySelector('.treemap-tooltip')

      if (!this.isInit) {
        this.isInit = true
        this.$nextTick(() => {
          this.resize()
        })
      }
    },
    destroyMyChart () {
      if (this.myChartNode) {
        this.myChartNode.removeEventListener('mousemove', this.transformTooltip)
        this.myChartNode.innerHTML = ''
      }
      if (this.myChart) {
        this.myChart = null
      }
    },
    setDataMyChart () {
      if (!this.myChart) return

      const arr = this.adapter.filterByPair
      arr.sort((a, b) => diff(a[this.tf][this.param], b[this.tf][this.param]))
      let formatedArr = arr

      this.levels = getLevelsArr(arr, this.tf)

      if (Array.isArray(this.filterColor) && this.filterColor.length) {
        formatedArr = formatedArr.filter((item => filterByColor.bind(this)(item)))
      }

      if (this.viewCount) {
        formatedArr = formatedArr.slice(0, this.viewCount)
      }

      this.myChartData = formatedArr
      this.myChart
        .data({
          name: 'root',
          children: formatedArr.map(v => {
            const tf = v[this.tf]

            const name = v.coin.label.replace('USDT', '')
            let paramInName = tf[this.param]
            let color = '#d5d5d5'
            switch (this.param) {
              case 'slidingVolume':
                color = getColor(tf.slidingVolume, this.levels, tf.slidingVolumePct > 0)
                paramInName = tf.slidingVolumeStr
                break
              case 'slidingVolumePct':
                color = getColor(tf.slidingVolume, this.levels, tf.slidingVolumePct > 0)
                paramInName = tf.slidingVolumePctStr
                break
              case 'pricePct':
                color = tf.colorPricePct
                paramInName = tf.pricePctStr
                break
              case 'rsiPct':
                color = getColorSimple(tf[this.param])
                paramInName = tf.rsiPctStr
                break
            }

            let ma = '—'
            switch (tf.ma200trend) {
              case 3: ma = `<span class="ml-1 circle-block _danger" />`; break
              case 2: ma = `<span class="ml-1 circle-block _warning" />`; break
              case 1: ma = `<span class="ml-1 circle-block _success" />`; break
            }

            let pricePctStrNode = tf.pricePctStr
            if (tf.pricePct > 0) pricePctStrNode = `<span class="text-success">${tf.pricePctStr}</span>`
            if (tf.pricePct < 0) pricePctStrNode = `<span class="text-danger">${tf.pricePctStr}</span>`

            let slidingVolumePctStrNode = tf.slidingVolumePctStr
            if (tf.slidingVolumePct > 0) slidingVolumePctStrNode = `<span class="text-success">${tf.slidingVolumePctStr}</span>`
            if (tf.slidingVolumePct < 0) slidingVolumePctStrNode = `<span class="text-danger">${tf.slidingVolumePctStr}</span>`

            let rsiPctStrNode = tf.rsiPctStr
            if (tf.rsiPct > 0) rsiPctStrNode = `<span class="text-success">${tf.rsiPctStr}</span>`
            if (tf.rsiPct < 0) rsiPctStrNode = `<span class="text-danger">${tf.rsiPctStr}</span>`

            return {
              name: name+' '+paramInName,
              nameFull: v.coin.label,
              value: Math.abs(tf[this.param]),
              color: color,
              price: tf.price,
              volatility: tf.volatility,
              pricePctStr: pricePctStrNode,
              slidingVolume: tf.slidingVolumeStr,
              slidingVolumePctStr: slidingVolumePctStrNode,
              rsi: tf.rsi,
              rsiPctStr: rsiPctStrNode,
              ma: ma,
              delta: tf.tf_quote_volume_delta_str,
              cdWeek: tf.tf1w_quote_volume_delta_str
            }
          })
        })
        .color('color')
        .tooltipContent(d => getTooltipContent(d, this.user.getAccess(1)))
    },
    changeParam () {
      if (!this.myChart) return

      this.myChart.zoomReset()
      this.setDataMyChart()
    },
    resize () {
      if (!this.myChart) return
      this.destroyMyChart()
      this.initMyChart()
      this.setDataMyChart()
      this.myChartNode.addEventListener('mousemove', this.transformTooltip)
    },
    resizeThrottle: throttle(function () {this.resize()}, 1000),
    transformTooltip (e) {
      const heightChart = this.myChartNode.getBoundingClientRect().height
      const heightTooltip = this.myTooltipNode.getBoundingClientRect().height
      if (heightChart - e.layerY - 21 < heightTooltip) {
        this.myTooltipNode.style.transform += ' translateY(calc(-100% - 25px))'
      }
    }
  }
}
</script>

<style lang="scss">
#visualization {
  flex-grow: 1;

  @include media-breakpoint-down(md) {
    width: 95%;
    margin: 0 auto;
  }
}
.treemap-viz {
  height: 100%;
  rect {
    opacity: 1 !important;
    stroke: #000000 !important;
    transition-duration: unset !important;
  }
}

.treemap-wrap {
  @include media-breakpoint-down(md) {
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 4rem;
  }
}
</style>
