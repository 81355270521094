import colorList from "@/assets/js/treemap/colorList.json";

export function getColor (value, levels, isPositive = false) {
  let colorLevel = '0'
  const valueAbs = Math.abs(value)
  if (valueAbs > levels[1]) {
    colorLevel = '2'
  } else if (valueAbs > levels[0]) {
    colorLevel = '1'
  }
  return colorList[colorLevel][isPositive ? 'positive' : 'negative']
}

export function getColorSimple(value) {
  if (value < 0) {
    return '#b66464'
  } else if (value > 0) {
    return '#469165'
  } else {
    return '#F9D327'
  }
}

export const diff = (a, b) => {
  const valA = a
  const valB = b
  if (typeof valA === 'string') return 1
  if (typeof valB === 'string') return -1
  return Math.abs(valB) - Math.abs(valA)
}

export function getTooltipContent (d, hasAccess) {
  return `
    Price ${d.price}<br/>
    Price change ${d.pricePctStr}<br/>
    Volume ${d.slidingVolume}<br/>
    Volume change ${d.slidingVolumePctStr}<br/>
    RSI ${d.rsi}<br/>
    RSI change ${d.rsiPctStr}<br/>
    Volatility ${d.volatility}<br/>
    <div class="d-flex">MA ${d.ma}</div>
    ${hasAccess ? `
    Delta ${d.delta}<br/>
    CD Week ${d.cdWeek}<br/>`: ''}
  `
}

export function getLevelsArr (arr, tf) {
  const mapped = []
  arr.forEach((item) => {
    let temp = Math.abs(item[tf].slidingVolume)
    if (!isNaN(temp)) { // some item[tf].slidingVolume is empty
      mapped.push(temp)
    }
  })
  const level2 = Math.max(...mapped)
  const level1 = level2 * 0.666
  const level0 = level2 * 0.333
  return [level0, level1, level2]
}

export function filterByColor (item) {
  const value = Math.abs(item[this.tf].slidingVolume)

  const colorIncludes = (filterColor) => (
    (item[this.tf].slidingVolumePct > 0 && filterColor.type === 'positive') ||
    (item[this.tf].slidingVolumePct < 0 && filterColor.type === 'negative')
  )

  const level0 = this.filterColor.some(el => el.level === 0 && colorIncludes(el))
  const level1 = this.filterColor.some(el => el.level === 1 && colorIncludes(el))
  const level2 = this.filterColor.some(el => el.level === 2 && colorIncludes(el))

  return (
    level0 && value < this.levels[0] ||
    level2 && value > this.levels[1] ||
    level1 && value > this.levels[0] &&
    value < this.levels[1]
  )
}
